<template>
  <AppHeader/>
  <main>
    <div class="container py-4">
      <nav class="d-flex">
        <div class="col-auto me-3">
          <router-link :to="{name: 'BuyGetOrders'}" class="btn btn-success">Покупка - Все заявки</router-link>
        </div>
        <div class="col-auto me-3">
          <router-link :to="{name: 'SellGetOrders'}" class="btn btn-danger">Продажа - Все заявки</router-link>
        </div>
        <div class="col-4 me-3">
          <select v-model="token" class="form-control">
            <option value="554bdf308839e11e09c8beab841ab3070d0c3b07">Ай</option>
            <option value="5b736f5f7f20e6d6219bbc65ea59d25138b87e14">Ст</option>
          </select>
        </div>
        <div class="col-auto">
          <button @click="saveToken()" class="btn btn-primary">Сохранить токен</button>
        </div>
      </nav>
      <div class="py-4">
        <router-view></router-view>
      </div>
    </div>
  </main>
  <AppFooter/>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
  name: 'App',
  components: {
    AppHeader, AppFooter
  },
  data() {
    return {
      token: ''
    }
  },
  created() {
    this.token = localStorage.accessToken
  },
  methods: {
    saveToken() {
      localStorage.accessToken = this.token;
      //location.href=location.href
    }
  }
}
</script>

<style>

</style>
