<template>
  <header>
    <div class="bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-12 py-3">
            <h4 class="text-white">BIM</h4>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppTop.vue"
}
</script>

<style scoped>

</style>