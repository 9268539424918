import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('../views/HomePage.vue'),
        meta: {
            title: 'Главная страница',
            breadcrumb: 'Главная'
        }
    },
    {
        path: '/buy/getOrders',
        name: 'BuyGetOrders',
        component: () => import('../views/buy/BuyGetOrders.vue'),
        meta: {
            title: 'Купить - Все заявки',
            breadcrumb: 'Купить - Все заявки'
        }
    },
    {
        path: '/buy/getOrders/acceptsMy/',
        name: 'BuyGetAcceptsMy',
        component: () => import('../views/buy/getOrders/BuyGetAcceptsMy.vue'),
        meta: {
            title: 'Купить - Все мои активные продажи',
            breadcrumb: 'Купить - Все мои активные продажи'
        }
    },

    {
        path: '/sell/getOrders',
        name: 'SellGetOrders',
        component: () => import('../views/sell/SellGetOrders.vue'),
        meta: {
            title: 'Купить - Все заявки',
            breadcrumb: 'Купить - Все заявки'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default  router