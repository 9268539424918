<template>
  <footer class="text-muted py-5">
    <div class="container">
      Подвал сайта
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppBottom.vue"
}
</script>

<style scoped>

</style>